<template>
  <div class='app-download-root'>
    <!--    banner轮播图-->
    <div class='app-download-banner-root'>
      <div class='app-download-banner'>
        <div class='banner-content'>
          <div class='header1'>提供一站式汽车检测 鉴定 评估服务</div>
          <div class='header2'>TESTING, APPRAISAL AND EVALUATION SERVICES</div>
          <div class='header3'>以数据化、标准化、便捷化的管理手段，为企业提供专业的、系统的库存融资业务及风险控制解决方案</div>
          <div class='divider'></div>
        </div>
        <img class='banner-qrcode hidden-md-and-down' src='@/assets/app-download-qrcode.png' />
        <el-button class='banner-qrcode hidden-lg-and-up' @click='handleClick()' type='text'>立即下载</el-button>
        <img class='banner-img' src='@/assets/app-download-banner-image1.png' />
      </div>
    </div>
    <!--    banner卡片-->
    <div class='app-download-card-root'>
      <div class='app-download-card-main'>
        <div class='card'>
          <img src='@/views/AppDownload/assets/appdownload-card-完善的车型库.svg' />
          <div class='card-main'>
            <div class='title'>完善的车型库</div>
            <div class='desc'>系统支持全品牌全车系车辆详细数据，系统使用人不需要通过各种途径进行人工搜索，在系统上便可获取二手车基本信息。</div>
          </div>
        </div>
        <div class='card'>
          <img src='@/views/AppDownload/assets/appdownload-card-信息收集及初审.svg' />
          <div class='card-main'>
            <div class='title'>信息收集及初审</div>
            <div class='desc'>二手车手续繁琐收集上耗时较长，通过系统收集的信息并完成初审，建立完整的二手车信息档案，大大节约客户经理的时间。</div>
          </div>
        </div>
        <div class='card'>
          <img src='@/views/AppDownload/assets/appdownload-card-在线核库.svg' />
          <div class='card-main'>
            <div class='title'>在线核库</div>
            <div class='desc'>系统可在线推送车辆信息，可实现异地巡库，极大减少人力成本及出差费用。</div>
          </div>
        </div>
        <div class='card'>
          <img src='@/views/AppDownload/assets/appdownload-card-提高业务实效.svg' />
          <div class='card-main'>
            <div class='title'>提高业务实效</div>
            <div class='desc'>业务部门与财务部门一般存在信息不对称，沟通不顺畅的情况，通过对专业系统传递给业务信息，可提高业务整体时效。</div>
          </div>
        </div>
      </div>
    </div>
    <!--    系统特点-->
    <div class='app-download-system-characteristics'>
      <div class='section-container'>
        <div class='section-header'>
          <div class='section-header-title'>
            系统特点
          </div>
          <div class='section-header-subtitle'>
            THE SYSTEM CHARACTERISTICS
          </div>
        </div>
        <div class='app-download-system-body'>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/预警通知.svg' />
            <span>预警通知</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/OCR证件识别.svg' />
            <span>OCR 证件识别</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/漆膜检测.svg' />
            <span>漆膜检测</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/额度设置.svg' />
            <span>额度设置</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/品牌车型库.svg' />
            <span>品牌车型库</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/车辆置换.svg' />
            <span>车辆置换</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/二次评估.svg' />
            <span>二次评估</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/厂商审批.svg' />
            <span>厂商审批</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/评估参数设置.svg' />
            <span>评估参数设置</span>
          </div>
          <div class='system-char-cell'>
            <img src='@/views/AppDownload/assets/业务模式设置.svg' />
            <span>业务模式设置</span>
          </div>
        </div>
      </div>
    </div>
    <div class='app-download-data-communication'>
      <div class='section-container'>
        <div class='section-header'>
          <div class='section-header-title'>
            数据互通
          </div>
          <div class='section-header-subtitle'>
            DATA COMMUNICATION
          </div>
        </div>
        <div class='section-body hidden-md-and-down'>
          <div class='data-cell'>
            <img src='@/views/AppDownload/assets/经销商.svg' />
            <span>经销商</span>
          </div>
          <img class='data-arrow' src='@/views/AppDownload/assets/arrow-icon.svg' />
          <div class='data-cell'>
            <img src='@/views/AppDownload/assets/车银通.svg' />
            <span>车银通</span>
          </div>
          <img class='data-arrow' src='@/views/AppDownload/assets/arrow-icon.svg' />
          <div class='data-cell'>
            <img src='@/views/AppDownload/assets/金融机构.svg' />
            <span>金融机构</span>
          </div>
          <img class='data-arrow' src='@/views/AppDownload/assets/arrow-icon.svg' />
          <div class='data-cell'>
            <img src='@/views/AppDownload/assets/监管方.svg' />
            <span>监管方</span>
          </div>
        </div>
        <div class='section-body hidden-lg-and-up'>
          <img class='appdownload-data-bg' src='@/assets/app-download-data-communication.png' />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { Dialog } from 'vant';

export default {
  name: 'App-Download',
  data() {
    return {};
  },
  methods: {
    handleClick() {
      const ios_url = 'http://itunes.apple.com/cn/app/id1464869809';
      const android_url = 'https://cyrt.oss-cn-beijing.aliyuncs.com/apk/cyrt.apk';
      //是否是微信浏览器
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        // 微信中打开
        Dialog({
          message: '请复制链接，在手机浏览器中打开网页\n\nhttps://cheyintong.com/app-download',
          confirmButtonColor: 'black',
        });
      } else {
        if (/(ios|ipad|iphone)/.test(navigator.userAgent.toLowerCase())) {
          window.open(ios_url);
        } else {
          window.open(android_url);
        }
      }
    },
  },
};
</script>
